import React from "react";
import { Button, Card, Row, Col, ListGroup } from 'react-bootstrap'
import { Link } from "react-router-dom"

const LatestTransactions = ({txs}) => {
    //console.log(txs);
    return (
        <Card  style={{backgroundColor:'#030f19'}}>
            <Card.Header style={{borderColor:'white', color:'white', borderWidth:'1px',borderColor:'#484646'}}>Latest Transactions</Card.Header>
            <Card.Body className="std-card-info" style={{backgroundColor:'rgba(0,0,0,0.0)',borderColor:'#484646',borderColor:'#484646'}}>
                <ListGroup variant="flush" className="list-group-item" style={{backgroundColor:'rgba(0,0,0,0.0)',borderColor:'#484646', borderRadius:'8px'}}> 
                    {txs.map((item, idx) => (
                        <ListGroup.Item key={idx} style={{backgroundColor:'rgba(0,0,0,0.0)',borderColor:'#484646' }}>
                            <Row className="list" style={{color:'#8f8f8f'}}>
                                <Col md={1} className="col-list">
                                    <Link to={`/tx/${item.hashLocal}`}>{item.status=="Success" ? <Button variant="success" style={{background:'#030f19', borderColor:'#030f19'}} size="sm" className="ml-2">Tx</Button> : <Button variant="danger" size="sm" className="ml-2">Tx</Button>}</Link>
                                </Col>
                                <Col md={5} className="col-list">
                                    <Row>
                                        <Link to={`/tx/${item.hashLocal}`}  title={item.hashLocal}>{item.hashLocal.slice(0, 25) + '...'}</Link>
                                        {/* <small>{item.hashLocal.slice(0, 30) } </small> */}
                                    </Row>
                                    <Row>
                                        <small>Bk:<Link to={`/block/${item.blockHeight}`}  title={item.blockHeight}>{item.blockHeight}</Link></small>
                                    </Row>
                                </Col>
                                <Col md={5} className="col-list">
                                    <Row>
                                        <Col>
                                            <small>From:</small> <Link to={`/address/${item.from}`} title={item.from}>{item.from.slice(0, 13) + '...'+item.from.slice(32, 42)}</Link>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {item.to ? <span><small>To:</small> <Link to={`/address/${item.to}`} title={item.to}>{item.to.slice(0, 13) + '...'+item.to.slice(32, 42)}</Link></span>
                                            : <span>Contract Creation</span>}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={1} className="col-list">
                                    <small>{item.network} </small>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    ))}
                        <ListGroup.Item style={{backgroundColor:'rgba(0,0,0,0.0)'}}>
                            <Link to="/txs">
                                <Button variant="secondary morebutton" size="sm" style={{background:'#030f19',borderColor:'#484646'}} className="ml-2">More transactions</Button>
                            </Link>
                    </ListGroup.Item>
                </ListGroup>
            </Card.Body>
        </Card>
    );
};
export default LatestTransactions;