
import { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'

import SearchBar from '../components/SearchBar'
import Dashboard from '../components/Dashboard'

import LatestBlocks from '../components/LatestBlocks'
import LatestTransactions from '../components/LatestTransactions'

import { TransactionApi } from '../apis/TransactionApi'
import { BlockApi } from '../apis/BlockApi'

const axios = require('axios').default;

const Home = ({ networkName, account }) => {
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState([])

    const [txs, setTxs] = useState([])
    const [pendingTxs, setPendingTxs] = useState([])
    const [lastBlock, setLastBlock] = useState(0)
    const [pendingBlocks, setPendingBlocks] = useState([])

    const [searchValue, setSearchValue] = useState('')
    const [searchFilter, setSearchFilter] = useState('all')
    const [searchAccount, setSearchAccount] = useState('')

    const [stats, setStats] = useState({
        dailytxnfee: 100,
        dailynetutilization: 100,
        avgdifficulty: 100,
        avgtxnperblock: 100,
        avgtxnperday: 100,
        avgtxnperhour: 100,
        avgtxnperminute: 100
    })

    const initialState = {
        address: "",
        pageNumber: 1,
        pageSize: 10,
        isSecret:false
    };

    const pageSize = {
        pageNumber: 1,
        pageSize: 10
    };

    const data = [
        {
          name: 'Page A',
          uv: 4000,
          pv: 2400,
          amt: 2400,
        },
        {
          name: 'Page B',
          uv: 3000,
          pv: 1398,
          amt: 2210,
        },
        {
          name: 'Page C',
          uv: 2000,
          pv: 9800,
          amt: 2290,
        },
        {
          name: 'Page D',
          uv: 2780,
          pv: 3908,
          amt: 2000,
        },
        {
          name: 'Page E',
          uv: 1890,
          pv: 4800,
          amt: 2181,
        },
        {
          name: 'Page F',
          uv: 2390,
          pv: 3800,
          amt: 2500,
        },
        {
          name: 'Page G',
          uv: 3490,
          pv: 4300,
          amt: 2100,
        },
      ];

    

    const getLatestTransactions = async () => {
        try
        {
            await TransactionApi.getLatestTransactions(initialState)
            .then((response) => 
            {
                console.log(response.data);
                setTxs(response?.data?.transactions);
            })
            .catch(function (error) {
            // handle error
                //console.log(error);
            })
            .then(function () {
                // always executed
            });
        }
        catch(err)
        {
            //console.log(err);
        }
    }

    const getLatestBlocks = async () => {
        try
        {
            await BlockApi.getLatestBlocks(initialState)
            .then((response) => 
            {
                //console.log(response.data);
                response.data.forEach(item => {
                    item.header.timeStamp = Math.round(+new Date()/1000) - item.header.timeStamp;
                    // console.log(item.header.timeStamp);
                    // console.log(item.validatorAddress);
                });
                //console.log(response.data.blocks);
                setItems(response.data);
            })
            .catch(function (error) {
            // handle error
                console.log(error);
            });
        }
        catch(err)
        {
            //console.log(err);
        }
    }
    
    useEffect(() => {
       try
       {
        if (loading) {
            getLatestBlocks();
            getLatestTransactions();
            setLoading(false);
        } else {
            let timer = setTimeout(() => {
                getLatestBlocks()
                getLatestTransactions()
            }, 3000);
            return () => clearTimeout(timer)
        }
       }catch(err)
       {
        //console.log(err);
       }
    })
    // Render ---------------------------------------------------------------------------------------------------------- //
    return (
        <main style={{ padding: "0px", marginTop:'-300px' }} className='app-body '>
            

            {/* Search Bar */}
            <SearchBar />

            {/* Stats */}
            {/* <Dashboard stats={stats}  data={data} /> */}

            {/* Blocks & Transactions */}
            <div className="mt-3">
                <Row style={{textAlign:'center',alignItems:'center', display:'ruby', margin:'0px'}}>
                    <Col xs={12} md={12} lg={5} xl={5}>
                        <LatestBlocks items={items} />
                    </Col>
                    <Col xs={12} md={12} lg={5} xl={5} style={{marginTop:'50px'}}>
                        <LatestTransactions txs={txs} />
                    </Col>
                </Row>
            </div>

        </main>
    );
}
export default Home